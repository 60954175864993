import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Paragraph } from 'components/core/Typography';
import { Image } from 'components/core/Image';

import garage from 'assets/images/landing/homepage/garage.svg';
import caret from 'assets/images/landing/homepage/down.svg';

import IconDropdownOption from './IconDropdownOption';

import './IconDropdown.scss';

export function IconDropdown({
	options,
	selectedLabel,
	setSelectedLabel,
	setShowOptions,
	showOptions,
	onSelect,
}) {
	return (
		<div
			className={classnames('icon-dropdown', { open: showOptions })}
			onClick={setShowOptions}
			role="button"
			tabIndex={0}
			onKeyPress={setShowOptions}
		>
			<div className="label">
				<Image
					src={garage}
					className="storage-icon"
				/>
				<Paragraph last>{selectedLabel}</Paragraph>
			</div>
			<Image
				src={caret}
				className="caret-icon"
			/>
			<ul className="options">
				{options.map((option) => (
					<li
						key={option.value}
						className="dropdown-item"
					>
						<IconDropdownOption
							value={option.value}
							label={option.label}
							selected={selectedLabel === option.label}
							onSelect={onSelect}
							setLabel={setSelectedLabel}
						/>
					</li>
				))}
			</ul>
		</div>
	);
}

IconDropdown.propTypes = {
	onSelect: PropTypes.func.isRequired,
	setSelectedLabel: PropTypes.func.isRequired,
	setShowOptions: PropTypes.func.isRequired,
	showOptions: PropTypes.bool.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}).isRequired,
	).isRequired,
	selectedLabel: PropTypes.string,
};

export default IconDropdown;
