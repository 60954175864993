import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Container } from 'components/core/Page';
import { H1 } from 'components/core/Typography';
import { Image } from 'components/core/Image';
import CSSTransition from 'react-transition-group/CSSTransition';
import BestPrice from 'assets/images/icons/best-price.svg';
import HomeHero from 'assets/images/icons/home-hero.svg';
import NearYou from 'assets/images/icons/near-you.svg';
import YourVehicle from 'assets/images/icons/your-vehicle.svg';
import { SearchSection } from 'components/landing';

import './HomeHeroBanner.scss';

export const enhance = compose(withRouter);

export function HomeHeroBanner() {
	const [textIndex, setTextIndex] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			if (textIndex < 2) {
				setTextIndex(textIndex + 1);
			} else {
				setTextIndex(0);
			}
		}, 5000);

		return () => clearInterval(timer);
	}, [textIndex]);

	return (
		<section className="hero-section">
			<Container className="top-row">
				<div className="text-column">
					<H1
						color="black"
						weight="extraBold"
						size="mega"
						className="hero-heading-top"
					>
						Find the Perfect Storage Unit
					</H1>

					<CSSTransition
						in={textIndex === 0}
						classNames="transition-fade-in"
						timeout={2000}
						unmountOnExit
					>
						<H1
							className="best-price animated-text"
							size="mega"
						>
							at the Best Price
							<Image
								alt="best price icon"
								src={BestPrice}
								className="best-price-image"
							/>
						</H1>
					</CSSTransition>

					<CSSTransition
						in={textIndex === 1}
						classNames="transition-fade-in"
						timeout={2000}
						unmountOnExit
					>
						<H1
							className="near-you animated-text"
							size="large"
						>
							Near You
							<Image
								src={NearYou}
								className="near-you-image"
							/>
						</H1>
					</CSSTransition>

					<CSSTransition
						in={textIndex === 2}
						classNames="transition-fade-in"
						timeout={2000}
						unmountOnExit
					>
						<H1
							className="for-your-vehicle animated-text"
							size="large"
						>
							for Your Vehicle
							<Image
								alt="your vehicle icon"
								src={YourVehicle}
								className="your-vehicle-image"
							/>
						</H1>
					</CSSTransition>
				</div>

				<div>
					<Image
						alt="home furnishing icon"
						src={HomeHero}
						className="home-hero-illustration"
					/>
				</div>
			</Container>
			<SearchSection />
		</section>
	);
}

export default enhance(HomeHeroBanner);
