import React from 'react';
import { H1, H3, Paragraph, Text } from 'components/core/Typography';
import { Image, Link } from '@sparefoot/react-core';
import RightArrow from 'assets/images/icons/right-arrow.svg';
import { generateStars } from 'utils/formatting';
import PropTypes from 'prop-types';
import './ReviewCTA.scss';

const ReviewCTA = ({ isHomePage = true, customHeader }) => (
	<div className={`review-cta-container ${!isHomePage && 'clp-container'}`}>
		{!isHomePage && customHeader ? (
			<div className="custom-header">
				<div className="star-container">{generateStars(4.5)}</div>
				<div className="rating-info">
					<H1
						size="mediumLarge"
						weight="extraBold"
					>
						We Have a
						<span className="rating">
							{' '}
							4.4 Out of 5 Star Rating
						</span>
					</H1>
				</div>
				<Paragraph
					size="standard-medium"
					color="gray"
					weight="standard"
				>
					Read what some of the over 59,000 customers who reviewed us
					on Trustpilot have to say.
				</Paragraph>
			</div>
		) : (
			<>
				<div className="star-container">{generateStars(4.5)}</div>
				{isHomePage ? (
					<H3 color="greenHero">Over One-Million People</H3>
				) : (
					<H3>
						<Text
							color="greenHero"
							size="standardMedium"
							weight="extraBold"
						>
							Over One-Million People
						</Text>
						<Text
							size="standardMedium"
							weight="extraBold"
						>
							Have Reserved Storage Through SelfStorage.com
						</Text>
					</H3>
				)}

				{isHomePage && <H3>Have Reserved Storage Through Us</H3>}

				{isHomePage ? (
					<Paragraph>
						Our 4.4 out of 5 star rating comes from over 60,000
						customer reviews on Trustpilot.
					</Paragraph>
				) : (
					<Paragraph>
						SelfStorage.com's 4.4 out of 5 star rating comes from
						real customer feedbck captured through Trustpilot
						reviews. View what some of our customers have to say
						below:
					</Paragraph>
				)}

				{isHomePage && (
					<Link
						href="https://www.trustpilot.com/review/www.selfstorage.com"
						target="_blank"
					>
						View All Reviews <Image src={RightArrow} />
					</Link>
				)}
			</>
		)}
	</div>
);

ReviewCTA.propTypes = {
	isHomePage: PropTypes.bool,
	customHeader: PropTypes.bool,
};

export default ReviewCTA;
