import React from 'react';
import PropTypes from 'prop-types';

import { HomePageSectionHeader } from 'components/landing/HomePageSectionHeader';
import { Image } from 'components/core/Image';
import { Container } from 'components/core/Page';

import size from 'assets/images/landing/homepage/size.svg';

import waveBackground from 'assets/images/layout/wave-background-bottom-blue.svg';
import HomePageUnitCard from '../HomePageUnitCard/HomePageUnitCard';

import './StorageUnitSize.scss';

export function StorageUnitSize({ units }) {
	return (
		<section className="storage-unit-size">
			<Container className="storage-unit-size-container">
				<HomePageSectionHeader
					icon={
						<Image
							src={size}
							alt="size-icon"
						/>
					}
					header="Picking a Storage Unit Size"
					description="Use our storage unit size guide to identify what size storage unit you need."
					link={{
						text: 'View The Full Size Guide',
						url: '/what-to-expect',
					}}
					theme="priceBlue"
				/>
				<div className="unit-card-arrangement">
					{units.map((unit) => (
						<HomePageUnitCard
							key={unit.title}
							unit={unit}
						/>
					))}
				</div>
			</Container>
			<Image
				src={waveBackground}
				className="wave-image"
			/>
		</section>
	);
}

StorageUnitSize.propTypes = {
	units: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			infoTag: PropTypes.objectOf(
				PropTypes.shape({
					measure: PropTypes.string,
					type: PropTypes.string,
				}),
			),
			infoTagTypes: PropTypes.arrayOf(PropTypes.string),
			looksLike: PropTypes.string,
			fits: PropTypes.string,
		}),
	),
};

export default StorageUnitSize;
