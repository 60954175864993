import React from 'react';
import Image from '@sparefoot/react-core/Image';
import Star from 'assets/images/icons/full-star.svg';
import HalfStar from 'assets/images/icons/half-star.svg';
import EmptyStar from 'assets/images/icons/empty-star.svg';
import states from 'config/states';

export function getInitials(name) {
	const nameParts = name.split(' ');
	const firstNameInitial = nameParts[0] ? nameParts[0][0] : '';
	const lastNameInitial = nameParts[1] ? nameParts[1][0] : '';

	if (!firstNameInitial && !lastNameInitial) {
		return '';
	}

	if (name.length === 2) {
		return name.substring(0, 2).toUpperCase();
	}

	return (
		(firstNameInitial.toUpperCase() || '') +
		(lastNameInitial.toUpperCase() || '')
	);
}

export const generateStars = (stars) => {
	const starsArray = [];

	for (let i = 1; i <= 5; i += 1) {
		if (i <= stars) {
			starsArray.push(
				<Image
					src={Star}
					key={i}
				/>,
			);
		} else if (i - 0.5 === stars) {
			starsArray.push(
				<Image
					src={HalfStar}
					key={i}
				/>,
			);
		} else {
			starsArray.push(
				<Image
					src={EmptyStar}
					key={i}
					className="empty-star-svg"
				/>,
			);
		}
	}

	return starsArray.map((star) => star);
};

export const stateAbbreviationToFullName = (abbreviation) =>
	states[abbreviation?.toUpperCase()]?.toLowerCase() || null;
